import React, { useState, useEffect } from 'react'
import { useParams, navigate } from '@reach/router'
import { useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'

import Button from '_components/button'
import Svg, { ICON } from '_components/svg'
import PictureInput from '_/views/setup-form/picture-input'
import { approvePicturesBudget, getBudget } from '_/modules/budget/actions'

import useStyles from './styles'
import useRolePermission from '_/hooks/use-role-permission'
import HeaderTitle from '_/components/header-title'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const ApprovalConfirmation = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { budgetId, serviceOrderId } = useParams()
  const [approvalConfItems, setApprovalConfItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const { checkUserPermission } = useRolePermission()

  useEffect(() => {
    Promise.resolve(dispatch(getBudget(serviceOrderId, budgetId))).then(res => {
      res.budgetApprovalConfirmationPictures?.map(item =>
        setApprovalConfItems([...approvalConfItems, { path: item.picture }])
      )
      setIsLoading(false)
    })
  }, [dispatch, serviceOrderId, budgetId])

  const handleItems = event => {
    const { value } = event.target
    setApprovalConfItems(value)
    dispatch(
      approvePicturesBudget(serviceOrderId, budgetId, {
        budget_approval_confirmation_pictures: value,
      })
    )
  }

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Grid className={styles.container}>
      <HeaderTitle title="Comprovante de aprovação do orçamento" backButtonAction={goBack}>
        {!isEditing && (
          <Button
            className={styles.edit}
            color="primary"
            endIcon={<Svg className={styles.icon} type={ICON.EDIT} />}
            onClick={() =>
              checkUserPermission(
                PERMISSIONS.BUDGETS,
                [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT],
                () => setIsEditing(true)
              )
            }
          >
            Editar
          </Button>
        )}
      </HeaderTitle>
      <Grid className={styles.main}>
        <Grid className={styles.content}>
          <PictureInput
            name="approvalConfirmation"
            value={approvalConfItems}
            onChange={handleItems}
            isDocument
            isLoading={isLoading}
            disabled={!isEditing}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ApprovalConfirmation
